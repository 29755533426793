* {
  padding: 0;
  margin: 0;
}

#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
